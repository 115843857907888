<template>
    <div class="actualites">
        <div class="actualites__filters">
            <div>
                <select v-model="selectedCategory" name="category" id="category">
                    <option value="">{{ trans['actualites']['all-categories'] }}</option>
                    <option v-for="(category, index) in trans['actualites']['categories']" :value="index">{{ category }}</option>
                </select>
            </div>
            <div>
                <select v-model="selectedTheme" name="theme" id="theme">
                    <option value="">{{ trans['actualites']['all-themes'] }}</option>
                    <option v-for="theme in themes" :value="theme.id">{{ theme.name }}</option>
                </select>
            </div>
        </div>

        <div class="actualites__container">
            <a :href="news.url" v-for="news in displayedItems" :class="'actualites__actualite' + (news.image ? '' : ' actualites__actualite--' + news.category_color)">
                <div v-if="news.image" class="actualites__photo">
                    <!-- <a class="actualites__photo-link" :href="news.url"> -->
                        <img class="actualites__photo-img" :src="news.image" :alt="news.title">
                    <!-- </a> -->
                </div>
                <div class="actualites__content">
                    <div class="actualites__date">{{ news.publication_date }}</div>
                    <!-- <a class="actualites__title-link" :href="news.url"> -->
                        <h3 class="actualites__title">{{ news.title }}</h3>
                    <!-- </a> -->
                </div>
                <div :class="'actualites__tag actualites__tag--' + news.category_color">{{ trans['actualites']['categories'][news.category] }}</div>
            </a>
        </div>

        <div v-if="lastPage && lastPage !== 1" class="actualites__pagination">
            <button v-if="currentPage !== 1" @click="changePage(currentPage - 1)" class="actualites__arrow actualites__arrow--prev" :title="trans['common']['previous']">
                <i class="icon-prev">{{ trans['common']['previous'] }}</i>
            </button>
            <button v-else disabled class="actualites__arrow actualites__arrow--prev disabled" :title="trans['common']['previous']">
                <i class="icon-prev">{{ trans['common']['previous'] }}</i>
            </button>

            <button v-if="currentPage !== lastPage" @click="changePage(currentPage + 1)" class="actualites__arrow actualites__arrow--next" :title="trans['common']['next']">
                <i class="icon-next">{{ trans['common']['next'] }}</i>
            </button>
            <button v-else disabled class="actualites__arrow actualites__arrow--next disabled" :title="trans['common']['next']">
                <i class="icon-next">{{ trans['common']['next'] }}</i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        trans: Object,
        allItems: Array,
        themes: Array,
        itemsPerPage: Number,
        oldPage: {
            type: Number,
            default: 1
        },
        oldFilters: {
            type: Object,
            default: function() {
                return {
                    'category': '',
                    'theme': ''
                };
            }
        }
    },
    data() {
        return {
            filteredItems: [],
            displayedItems: [],
            currentPage: this.oldPage,
            lastPage: 1,
            selectedCategory: this.oldFilters.category,
            selectedTheme: this.oldFilters.theme
        }
    },
    mounted() {
        window.addEventListener('popstate', function(event) {
            if(event.state) {
                this.currentPage = event.state.page;

                const filters = JSON.parse(event.state.filters);
                this.selectedCategory = filters.category;
                this.selectedTheme = filters.theme;
            } else {
                this.currentPage = 1;
                this.selectedCategory = '';
                this.selectedTheme = '';
            }
            this.filterData();
        }.bind(this));

        this.filterData();
    },
    watch: {
        selectedCategory: function() {
            this.resetPage();
        },
        selectedTheme: function() {
            this.resetPage();
        }
    },
    methods: {
        filterData() {
            const that = this;

            this.filteredItems = this.allItems
                .filter(function(value, index) {
                    if(that.selectedCategory && value.category !== that.selectedCategory) {
                        return false;
                    }

                    if(that.selectedTheme && !value.themes_id.includes(that.selectedTheme.toString())) {
                        return false;
                    }

                    return true;
                });

            this.lastPage = Math.ceil(this.filteredItems.length / this.itemsPerPage);

            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = this.currentPage * this.itemsPerPage;
            this.displayedItems = this.filteredItems.slice(start, end);
        },
        setHistoryChange() {
            const filters = JSON.stringify({
                'category': this.selectedCategory,
                'theme': this.selectedTheme
            });

            var url = new URL(window.location);
            url.searchParams.set('page', this.currentPage);
            url.searchParams.set('filters', filters);
            history.pushState({'page': this.currentPage, 'filters': filters}, '', url);
        },
        changePage(page, force = false) {
            if(page !== this.currentPage || force) {
                this.currentPage = page;
                this.setHistoryChange();
                this.filterData();
                window.scrollTo(0, $('.actualites').first().offset().top - 40);
            }
        },
        resetPage() {
            this.$nextTick(() => this.changePage(1, true));
        }
    }
}
</script>
