
/**
 * Vue.js
 */
import { createApp } from 'vue';
window.VueModel = createApp({});

/*
 * Custom components
 */
import NewsSearchEngine from './components/NewsSearchEngine.vue';
VueModel.component('news-search-engine', NewsSearchEngine);

import PublicationsSearchEngine from './components/PublicationsSearchEngine.vue';
VueModel.component('publications-search-engine', PublicationsSearchEngine);

import ContactForm from './components/ContactForm.vue';
VueModel.component('contact-form', ContactForm);

window.VueComponents = {};

VueModel.mount('#app');
