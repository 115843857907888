require('./bootstrap');
import './vue';

window.$ = window.jQuery = require('jquery');

window.bodyScrollLock = require('body-scroll-lock');
window.modal = require('jquery-modal');

import Glide from '@glidejs/glide';

// Get body
const body = document.querySelector('body');
// Get header
const header = document.querySelector('.site-header');
// Get all main navigation parent links
const navParentLinks = document.querySelectorAll('.site-header__nav-link--parent');

let scrollPosition = window.pageYOffset; // window scroll position
let didScroll;
let lastScrollTop = 0;
let delta = 1;
let headerHeight = header.offsetHeight;

const headerHiddenClass = 'header-hidden'; // class used on <body> for hiding header
const homeHeaderScrolled = 'home-header-scrolled'; // class used on <body> for showing background color on header on homepage

// Stick section subnavigation to top of screen on scroll
window.addEventListener('scroll', function () {
    scrollPosition = window.pageYOffset; // update scroll position
    didScroll = true; // on scroll, let the interval function know the user has scrolled
});

let event = document.createEvent("HTMLEvents");
event.initEvent("scroll", false, true);
window.dispatchEvent(event);

function hasScrolled() {
    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - scrollPosition) <= delta)
        return;

    // If they scrolled down and are past the header, add hidden header class to <body>
    // This is necessary so you never see what is "behind" the header
    if (scrollPosition > lastScrollTop && scrollPosition > headerHeight) {
        // Scroll Down
        if (document.querySelector('.mobile-menu-visible') == null) { // Check that mobile menu is not already open
            body.classList.add(headerHiddenClass);
        }
    } else {
        // Scroll Up
        body.classList.remove(headerHiddenClass);
    }

    // If scrolled down on homepage, add class to show background color on header
    if (scrollPosition > headerHeight && body.classList.contains('home')) {
    	body.classList.add(homeHeaderScrolled);
    } else {
    	body.classList.remove(homeHeaderScrolled);
    }

    lastScrollTop = scrollPosition;
}

// run hasScrolled function and reset didScroll status
setInterval(function () {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 100);

// Add active state to main nav parent link on click for displaying associated subnavigation
navParentLinks.forEach((el, i) => {
	// Nav parent link click
	el.addEventListener('click', (event) => {
		event.preventDefault(); // prevent from scrolling to top of page
		// Check if active
		if (el.classList.contains('active')) {
			el.classList.remove('active'); // Remove active class from parent link if already active
			header.removeAttribute('data-header-theme'); // Remove header theme color
		} else {
			navParentLinks.forEach((el, i) => {
				el.classList.remove('active'); // Remove active class from all parent links
			});
			el.classList.add('active'); // Apply active class to selected parent link
			header.setAttribute('data-header-theme', el.parentNode.getAttribute('data-section-theme')); // Set header theme color
		}
	});
});

// Mobile menu
const menuToggle = document.querySelectorAll('.menu-toggle');
const menu = document.querySelector('.site-header__content');
const mobileMenuVisible = 'mobile-menu-visible'; // class used on <body> for mobile menu

menuToggle.forEach(button => button.addEventListener('click', function () {
    // Add class to <body> to toggle main menu visibility on menu-toggle button click
    body.classList.toggle(mobileMenuVisible);

    // Toggle <body> scroll lock
    if (body.classList.contains(mobileMenuVisible)) {
        bodyScrollLock.disableBodyScroll(menu); // Disable lock
    } else {
        bodyScrollLock.enableBodyScroll(menu); // Enable lock
    }
}));

// Home Stats section Glide.js slider
if (document.querySelector('.home-stats__slider')) {
	let statsSlider = new Glide('.home-stats__slider', {
		autoplay: 8000
	}).mount()
}

// Home Publications section Glide.js slider
if (document.querySelector('.home-publications__slider')) {
	let publicationsSlider = new Glide('.home-publications__slider', {
		perView: 4,
		gap: 24,
		breakpoints: {
			1025: {
				perView: 3
			},
			769: {
				perView: 2
			},
			578: {
				perView: 1
			}
		}
	}).mount()

	// Previous button
	document.querySelector('.home-publications__arrow--prev').addEventListener('click', function(){
		publicationsSlider.go('|<');
	});
	// Next button
	document.querySelector('.home-publications__arrow--next').addEventListener('click', function(){
		publicationsSlider.go('|>');
	});
}

// IRC Members list infos toggle
if (document.querySelector('.membres-blocks__region-handle')) {
	let handle = document.querySelectorAll('.membres-blocks__region-handle');

	handle.forEach(button => button.addEventListener('click', function () {
		let parent = this.parentNode; // get parent of handle
		let content = this.nextElementSibling; // get next sibling of handle

		// Slide down
		if (!parent.classList.contains('membres-blocks__infos--expanded')) {
			parent.classList.add('membres-blocks__infos--expanded'); // add active class

			// reset height
			content.style.height = "auto";

			// get height of the content
			let height = content.offsetHeight + "px";

			// set height to 0
			content.style.height = "0px";

			// apply content height after delay to trigger slide down transition
			setTimeout(function() {
				content.style.height = height;
			}, 0);

		// Slide up
		} else {
			// set height to 0 to trigger slide up transition
			content.style.height = "0px";

			// remove active class
			parent.classList.remove('membres-blocks__infos--expanded');
		}
	}));
}

// Réussite page Facteurs section Glide.js sliders
if (document.querySelector('.facteurs-carousel__slider')) {
	let facteursSliders = document.querySelectorAll('.facteurs-carousel__slider');
	let conf = {
		perView: 3,
		bound: true,
		gap: 10,
		breakpoints: {
			1025: {
				perView: 2
			},
			900: {
				perView: 1
			},
			769: {
				perView: 3
			},
			578: {
				perView: 2
			},
			400: {
				perView: 1
			}
		},
		peek: {
			before: 0,
			after: 100,
		}
	}

	facteursSliders.forEach(item => {
		// initiate sliders
		let glide = new Glide(item, conf).mount();

		// slides
		let slides = item.querySelectorAll('.facteurs-carousel__slide');
		let slidesLength = slides.length;

		// navigation
		let nav = item.querySelector('.facteurs-carousel__nav');

		// show/hide navigation arrows based on number of visible slides
		let toggleNavVisibility = function() {
			if (slidesLength <= 1 || slidesLength <= glide.settings.perView) {
				nav.classList.add('hidden');
			} else {
				nav.classList.remove('hidden');
			}
		};

		toggleNavVisibility();

		// check for navigation arrows visibility on slider resize
		glide.on('resize', function() {
			toggleNavVisibility();
		});
	});
}

// Travail des jeunes section Glide.js slider
if (document.querySelector('.conciliation-raisons__slider')) {
	let statsSlider = new Glide('.conciliation-raisons__slider', {
		autoplay: 4000
	}).mount()
}

// Travail des jeunes accordions
if (document.querySelector('.conciliation-employeurs-accordions__handle')) {
	let handle = document.querySelectorAll('.conciliation-employeurs-accordions__handle');

	handle.forEach(button => button.addEventListener('click', function () {
		let parent = this.parentNode; // get parent of handle
		let content = this.nextElementSibling; // get next sibling of handle

		// Slide down
		if (!parent.classList.contains('conciliation-employeurs-accordions__block--expanded')) {
			parent.classList.add('conciliation-employeurs-accordions__block--expanded'); // add active class

			// reset height
			content.style.height = "auto";

			// get height of the content
			let height = content.offsetHeight + "px";

			// set height to 0
			content.style.height = "0px";

			// apply content height after delay to trigger slide down transition
			setTimeout(function() {
				content.style.height = height;
			}, 0);

		// Slide up
		} else {
			// set height to 0 to trigger slide up transition
			content.style.height = "0px";

			// remove active class
			parent.classList.remove('conciliation-employeurs-accordions__block--expanded');
		}
	}));
}

// Animated stats numbers
if (document.querySelector('[data-counter]')) {
	let dataCounters = document.querySelectorAll("[data-counter]"); // Get all elements with data-counter attribute

	// Set each counters as inactive
	dataCounters.forEach(el => {
		el.isActive = false;
	});
	
	// Count up animation
	const counter = (el) => {
		const duration = 1000; // Animate all counters equally for a better UX

		// Get start and end values
		const start = parseInt(el.textContent.replace(" ", ""), 10); // Get start value (and remove spaces)
		const end = parseInt(el.dataset.counter, 10); // Get end value

		if (start === end) return; // If equal values, stop here.

		const range = end - start; // Get the range
		let curr = start; // Set current at start position

		const timeStart = Date.now();

		const loop = () => {
			let elaps = Date.now() - timeStart;
			if (elaps > duration) elaps = duration; // Stop the loop
			const frac = elaps / duration; // Get the time fraction
			const step = frac * range; // Calculate the value step
			curr = start + step; // Increment or Decrement current value
			const number = Math.trunc(curr).toLocaleString('fr-FR'); // Format to add spaces
			el.textContent = number; // Apply
			if (elaps < duration) requestAnimationFrame(loop); // Loop
		};

		requestAnimationFrame(loop); // Start the loop!
	};

	// Check if visible in viewport
	const isInViewport = (el) => {
	    // Get the bounding client rectangle position in the viewport
	    let bounding = el.getBoundingClientRect();
	    
	    // Checking part. Here the code checks if it's *fully* visible
	    // Edit this part if you just want a partial visibility
	    if (
	        bounding.top >= 0 &&
	        bounding.left >= 0 &&
	        bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
	        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
	    ) {
	        //console.log('Visible');
	        return true;
	    } else {
	        //console.log('Not visible');
	        return false;
	    }
	}

	// On scroll, if visible in viewport and inactive, trigger counter animation
	window.addEventListener('scroll', function (event) {
		dataCounters.forEach(el => {
			if (isInViewport(el) && !el.isActive) {
				el.isActive = true; // Make counter active
				counter(el); // Trigger counter animation
		    }
		});
	}, false);
	window.dispatchEvent(new Event('scroll')); // Trigger scroll event if visible without scrolling
}

// Medias press releases section Glide.js slider
if (document.querySelector('.medias-publications__slider')) {
    let pressReleasesSlider = new Glide('.medias-publications__slider', {
        perView: 4,
        gap: 24,
        breakpoints: {
            1025: {
                perView: 3
            },
            769: {
                perView: 2
            },
            578: {
                perView: 1
            }
        }
    }).mount()

    // Previous button
    document.querySelector('.medias-publications__arrow--prev').addEventListener('click', function(){
        pressReleasesSlider.go('|<');
    });
    // Next button
    document.querySelector('.medias-publications__arrow--next').addEventListener('click', function(){
        pressReleasesSlider.go('|>');
    });
}


// ***************************************************************
//  jQuery
// ***************************************************************

$(document).ready(function() {
    $('.membres-blocks__content p:last-child a').append('<i class="icon-arrow-external"></i>');

    // Réussite video modals
    $('.facteurs-carousel__button--video-modal').on('click', function(event){ // Open modal on button click
	    event.preventDefault();

	    let vimeoID = $(this).attr('data-vimeo-id');

	    // Set iframe to correct video source
	    $('.video-modal__iframe').attr('src', 'https://player.vimeo.com/video/' + vimeoID + '?h=760d0cd310&autoplay=1&title=0&byline=0&portrait=0');

	    // Fade in modal
	    $('#video').modal({
	        fadeDuration: 250
	    });
	});

	// Stop video when closing video modal
	$('#video').on($.modal.CLOSE, function(event, modal) {
	    $('.video-modal__iframe').attr('src', '');
	});
});
