<template>
    <div class="publications">
        <div class="publications__filters">
            <div>
                <select v-model="selectedCategory" name="category" id="category">
                    <option value="">{{ trans['publications']['all-categories'] }}</option>
                    <option v-for="(category, index) in trans['publications']['categories']" :value="index">{{ category }}</option>
                </select>
            </div>
            <div>
                <select v-model="selectedTheme" name="theme" id="theme">
                    <option value="">{{ trans['publications']['all-themes'] }}</option>
                    <option v-for="theme in themes" :value="theme.id">{{ theme.name }}</option>
                </select>
            </div>
        </div>

        <div class="publications__container">
            <template v-for="publication in displayedItems">
                <div class="publications__publication">
                    <div>
                        <div class="publications__date">{{ publication.publication_date }}</div>
                        <div class="publications__title">
                            <a :href="publication.url" target="_blank" class="publications__title-link">
                            {{ publication.title }}</a>
                        </div>
                    </div>
                    <div>
                        <div class="publications__category">{{ trans['publications']['categories'][publication.category] }}</div>
                        <div class="publications__themes">
                            <template v-for="theme in publication.themes.split(', ')">
                                <span>{{ theme }}</span>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <div v-if="lastPage && lastPage !== 1" class="publications__pagination">
            <button v-if="currentPage !== 1" @click="changePage(currentPage - 1)" class="publications__arrow publications__arrow--prev" :title="trans['common']['previous']">
                <i class="icon-prev">{{ trans['common']['previous'] }}</i>
            </button>
            <button v-else disabled class="publications__arrow publications__arrow--prev disabled" :title="trans['common']['previous']">
                <i class="icon-prev">{{ trans['common']['previous'] }}</i>
            </button>

            <button v-if="currentPage !== lastPage" @click="changePage(currentPage + 1)" class="publications__arrow publications__arrow--next" :title="trans['common']['next']">
                <i class="icon-next">{{ trans['common']['next'] }}</i>
            </button>
            <button v-else disabled class="publications__arrow publications__arrow--next disabled" :title="trans['common']['next']">
                <i class="icon-next">{{ trans['common']['next'] }}</i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        trans: Object,
        allItems: Array,
        themes: Array,
        itemsPerPage: Number,
        oldPage: {
            type: Number,
            default: 1
        },
        oldFilters: {
            type: Object,
            default: function() {
                return {
                    'category': '',
                    'theme': ''
                };
            }
        }
    },
    data() {
        return {
            filteredItems: [],
            displayedItems: [],
            currentPage: this.oldPage,
            lastPage: 1,
            selectedCategory: this.oldFilters.category,
            selectedTheme: this.oldFilters.theme
        }
    },
    mounted() {
        window.addEventListener('popstate', function(event) {
            if(event.state) {
                this.currentPage = event.state.page;

                const filters = JSON.parse(event.state.filters);
                this.selectedCategory = filters.category;
                this.selectedTheme = filters.theme;
            } else {
                this.currentPage = 1;
                this.selectedCategory = '';
                this.selectedTheme = '';
            }
            this.filterData();
        }.bind(this));

        this.filterData();
    },
    watch: {
        selectedCategory: function() {
            this.resetPage();
        },
        selectedTheme: function() {
            this.resetPage();
        }
    },
    methods: {
        filterData() {
            const that = this;

            this.filteredItems = this.allItems
                .filter(function(value, index) {
                    if(that.selectedCategory && value.category !== that.selectedCategory) {
                        return false;
                    }

                    if(that.selectedTheme && !value.themes_id.includes(that.selectedTheme.toString())) {
                        return false;
                    }

                    return true;
                });

            this.lastPage = Math.ceil(this.filteredItems.length / this.itemsPerPage);

            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = this.currentPage * this.itemsPerPage;
            this.displayedItems = this.filteredItems.slice(start, end);
        },
        setHistoryChange() {
            const filters = JSON.stringify({
                'category': this.selectedCategory,
                'theme': this.selectedTheme
            });

            var url = new URL(window.location);
            url.searchParams.set('page', this.currentPage);
            url.searchParams.set('filters', filters);
            history.pushState({'page': this.currentPage, 'filters': filters}, '', url);
        },
        changePage(page, force = false) {
            if(page !== this.currentPage || force) {
                this.currentPage = page;
                this.setHistoryChange();
                this.filterData();
                window.scrollTo(0, $('.publications').first().offset().top - 40);
            }
        },
        resetPage() {
            this.$nextTick(() => this.changePage(1, true));
        }
    }
}
</script>
