<template>
    <div>
        <form @submit.prevent="submitForm" method="POST" :action="api" class="contact-form__form">
            <div class="grid">
                <div class="col-6_xs-12">
                    <label for="firstname">Prénom</label>
                    <input v-model="firstname" type="text" name="firstname" id="firstname" required>
                </div>
                <div class="col-6_xs-12">
                    <label for="lastname">Nom</label>
                    <input v-model="lastname" type="text" name="lastname" id="lastname" required>
                </div>
                <div class="col-6_xs-12">
                    <label for="phone">Téléphone</label>
                    <input v-model="phone" type="text" name="phone" id="phone">
                </div>
                <div class="col-6_xs-12">
                    <label for="email">Courriel</label>
                    <input v-model="email" type="email" name="email" id="email">
                </div>
                <div class="col-12">
                    <label for="subject">Sujet</label>
                    <div class="select">
                        <select v-model="subject" name="subject" id="subject" required>
                            <option v-for="subject in trans['contact']['subjects']" :value="subject">{{ subject }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-12">
                    <label for="message">Message</label>
                    <textarea v-model="message" name="message" id="message" rows="10" required></textarea>
                </div>
                <div class="col-12">
                    <button v-if="!formBeingSent" class="button button--pink" type="submit">Envoyer</button>
                    <button v-else class="button button--pink" type="button" disabled>Envoyer</button>
                </div>
            </div>
        </form>
        <div v-if="formSentSuccessfully" class="contact-form__confirmation">
            <p>Nous vous remercions d’avoir communiqué avec le Réseau québécois pour la réussite éducative. Une personne de notre équipe vous répondra le plus rapidement possible.</p>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            api: String,
            trans: Object
        },
        data() {
            return {
                firstname: '',
                lastname: '',
                phone: '',
                email: '',
                subject: this.trans['contact']['subjects'][1],
                message: '',
                formBeingSent: false,
                formSentSuccessfully: false
            }
        },
        methods: {
            submitForm() {
                const that = this;

                axios.post(this.api, {
                    'firstname': this.firstname,
                    'lastname': this.lastname,
                    'phone': this.phone,
                    'email': this.email,
                    'subject': this.subject,
                    'message': this.message
                }).then(function() {
                    that.formSentSuccessfully = true;
                }).catch(error => {
                    console.warn(error);
                });
            }
        }
    }
</script>
